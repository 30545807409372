<template>
  <div class="number">
    <a-tooltip :trigger="Triggers" placement="topLeft">
      <span v-if="val" slot="title" class="numeric-input-title">
        {{ Amountval }}
      </span>
      <a-input
        ref="int"
        v-model="val"
        :style="{'text-align':`${textAlign}`}"
        :size="size"
        :max-length="maxlength"
        :placeholder="placeholder"
        :type="myType"
        :disabled="disabled"
        :suffix="suffix"
        :prefix="prefix"
        :allow-clear="allowClear"
        @focus="focus"
        @change="change"
        @input="valChange"
        @blur="blur"
      >
        <!-- 前置标签 -->
        <template slot="addonBefore">
          <slot name="addonBefore" />
        </template>
        <!-- 后置标签 -->
        <template slot="addonAfter">
          <slot name="addonAfter" />
        </template>
      </a-input>
    </a-tooltip>
  </div>
</template>

<script>
import { getData, getData1, numerAmount } from '@/utils/num-handle'
// 只能输入纯数字
export default {
  name: 'Number',
  model: {
    prop: 'defultVal',
    event: 'echoVal'
  },
  props: {
    defultVal: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: 11
    },
    max: {
      type: [Number, String],
      default: 9999999999999
    },
    min: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'default'
    },
    // 是否允许输入0
    isZero: {
      type: Boolean,
      default: true
    },
    // 是否自动获取焦点
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 精确到几位小数
    precision: {
      type: [Number, String],
      default: 0
    },
    // 数字金额转中文大写金额
    numamount: {
      type: [String],
      default: 'false'
    },
    // 是否可以输入小数点
    decimalSeparator: {
      type: Boolean,
      default: false
    },
    // 是否可以输入负数 必须设置min
    negativeNumber: {
      type: Boolean,
      default: false
    },
    // 是否显示清空icon
    allowClear: {
      type: Boolean,
      default: false
    },
    // 是否默认补充0，当decimalSeparator为true并且precision大于0的情况下条件生效
    isFillZero: {
      type: Boolean,
      default: false
    },
    // 是否添加千位分隔符,当decimalSeparator为true并且precision大于0的情况下条件生效
    // 整数添加千位分隔符 decimalSeparator为false ，precision不可设参数
    isThousands: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      val: '',
      myType: 'text',
      Amountval: '',
      Triggers: ''
    }
  },
  watch: {
    defultVal(newVal) {
      this.val = newVal
    },
    val(newVal, oldVal) {
      // 如果为空字符串则不走下面逻辑直接返回空字符串
      if (this.val === '' || this.val === undefined || this.val === null) {
        this.$emit('change', this.val)
        this.$emit('echoVal', this.val)
        return
      }
      if (this.decimalSeparator) {
        if (this.negativeNumber) {
          // 若可以输入负数
          if (this.isThousands && this.val) {
            this.val = String(this.val)
          } else {
            this.val = String(this.val).replace(/^(-)?\D*(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
          }
        } else {
          if (this.isThousands && this.val) {
            this.val = String(this.val)
          } else {
            this.val = String(this.val).replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
          }
        }
        if (this.val === '00') this.val = 0
        if (this.precision) {
          this.val = this.calcPrecision(this.val)
        }
      } else {
        if (this.negativeNumber) {
          // 若可以输入负数
          if (this.isThousands && this.val) {
            this.val = String(this.val)
          } else {
            this.val = String(this.val).replace(/[^-\d]/g, '')
          }
        } else {
          if (this.isThousands && this.val) {
            this.val = String(this.val)
          } else {
            this.val = String(this.val).replace(/[^\d]/g, '')
          }
        }
        if (newVal * 1 === 0 && !this.isZero) {
          this.val = ''
          this.$emit('change', this.val)
          this.$emit('echoVal', this.val)
          return
        }
        // 防止可以输入0000这种数字
        if (newVal * 1 === 0 && String(newVal).length > 1) this.val = 0
      }
      // 展示的最大值的限制
      if (JSON.stringify(this.max) !== 'undefind' && newVal * 1 > this.max * 1) {
        this.val = Number(this.max) === 0 ? '' : this.max
      }
      // 最小值限制
      // if (JSON.stringify(this.min) !== 'undefind' && this.min !== '' && newVal * 1 < this.min * 1) {
      //   this.val = Number(this.min) === 0 ? '' : this.min
      // }
      // 输入框处于禁用状态下，值变化一次就补充一次0
      // 非禁用状态下不要去补充，是因为如果补充则输入会自动改变值影响用户操作输入
      if (this.disabled && this.decimalSeparator && this.precision && this.isFillZero) {
        this.val = this.fillZero(this.val) || ''
      }
      this.$emit('change', this.val)
      this.$emit('echoVal', this.val)
    }
  },
  mounted() {
    this.val = JSON.parse(JSON.stringify(this.defultVal))
    this.$nextTick(() => {
      if (this.autofocus) this.$refs.int.focus()
    })
  },
  methods: {
    valChange() {
      let res = this.val
      // 密码框的特殊处理要不然这里总是默认填上浏览器缓存的账号密码
      if (this.type === 'password') {
        this.myType = 'password'
      }
      if (this.decimalSeparator && this.precision) {
        res = this.calcPrecision(res)
      }
      this.$emit('echoVal', res)
    },
    // 计算保留几位小数的方法
    calcPrecision(val) {
      const copyVal = val + ''
      if (copyVal.indexOf('.') !== -1) {
        const numList = copyVal.split('.')
        const int = numList[0]
        const float = numList[1]
        return `${int}.${float.substring(0, this.precision)}`
      } else {
        return copyVal
      }
    },
    focus() {
      if (this.isThousands) {
        this.val = this.val.split(',').join('')
      }
      // 当输入值默认填充0补全的情况下，获取光标自动去除填充的0
      // if (this.decimalSeparator && this.precision && this.isFillZero) {
      if (this.val === 0 || this.val === '0' || this.val) {
        this.val = parseFloat(Number(this.val))
        // }
      }
      this.$emit('focus')
    },
    change(e) {
      const val = e.data
      if (this.decimalSeparator && this.precision) {
        this.val = this.calcPrecision(this.val)
      }
      this.$emit('change', val)
    },
    blur(e) {
      let val = e.target.value
      if (val !== 0 && !val) {
        this.val = ''
      } else {
        // 最小值限制
        if (JSON.stringify(this.min) !== 'undefind' && this.min !== '' && this.val * 1 < this.min * 1) {
          this.val = Number(this.min) === 0 ? '' : this.min
        }
        if (this.decimalSeparator && String(val).indexOf('.') === String(val).length - 1) {
          val = val.replaceAll('.', '')
          this.val = this.val.replaceAll('.', '')
        }

        // 失去焦点后，根据是否包含小数点，自动格式化，将050格式化为50
        if (this.decimalSeparator && Number(this.precision) > 0) {
          const number = this.val
          if (number.indexOf('-') !== -1) {
            this.val = this.val.split('-')[1]
          }
          if (this.val) {
          // 失去焦点自动填充千位分隔符及小数部分
            if (this.isThousands && Number(this.val) !== 0) {
              if (this.val.indexOf('.') !== -1) {
                const arr = this.val.split('.')
                arr[0] = getData(arr[0], this.precision).split('.')[0]
                if (arr[1] && arr[1].length) {
                  for (let i = 0; i <= this.precision - arr[1].length; i++) {
                    arr[1] += '0'
                  }
                }
                this.val = arr.join('.')
              } else {
                this.val = getData(this.val, this.precision)
              }
            } else {
              this.val = Number(this.val).toFixed(Number(this.precision))
            }
            if (number.indexOf('-') !== -1) {
              this.val = '-' + this.val
            }
          }
        } else if (!this.decimalSeparator && this.precision === 0 && this.isThousands) {
          if (this.val && this.val.indexOf('-') !== -1) {
            const arr = this.val.split('-')
            this.val = '-' + getData1(arr[1])
          // 当需要千位分隔符,且保留整数时
          } else if (this.val) {
            this.val = getData1(this.val)
          }
        } else {
          if (this.val) {
            this.val = String(Number(this.val))
          }
        }
        if (this.decimalSeparator && this.precision && this.isFillZero) {
          this.val = this.fillZero(this.val) || ''
          this.$emit('echoVal', this.val)
        }
        // 数字金额转中文大写金额 100转壹佰
        if (this.numamount === 'true' && (val !== '' || val !== null)) {
          this.Amountval = numerAmount(val)
          if (this.Amountval === '') {
            this.Triggers = ''
          } else {
            this.Triggers = ['hover']
          }
        }
      }
      this.$emit('blur', val)
    },
    // 自动填充0
    fillZero(val) {
      if (this.val === '' || this.val === undefined) return ''
      const copyVal = val + ''
      if (copyVal.indexOf('.') !== -1) {
        const numList = copyVal.split('.')
        const int = numList[0]
        let float = numList[1]
        if (float.length < this.precision) {
          for (let i = float.length; i < this.precision; i++) {
            float += '0'
          }
        }
        return `${int}.${float}`
      } else {
        let str = ''
        for (let i = 0; i < this.precision; i++) {
          str += '0'
        }
        return `${copyVal}.${str}`
      }
    }
  }
}
</script>
