var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "number" },
    [
      _c(
        "a-tooltip",
        { attrs: { trigger: _vm.Triggers, placement: "topLeft" } },
        [
          _vm.val
            ? _c(
                "span",
                {
                  staticClass: "numeric-input-title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" " + _vm._s(_vm.Amountval) + " ")]
              )
            : _vm._e(),
          _c(
            "a-input",
            {
              ref: "int",
              style: { "text-align": "" + _vm.textAlign },
              attrs: {
                size: _vm.size,
                "max-length": _vm.maxlength,
                placeholder: _vm.placeholder,
                type: _vm.myType,
                disabled: _vm.disabled,
                suffix: _vm.suffix,
                prefix: _vm.prefix,
                "allow-clear": _vm.allowClear,
              },
              on: {
                focus: _vm.focus,
                change: _vm.change,
                input: _vm.valChange,
                blur: _vm.blur,
              },
              model: {
                value: _vm.val,
                callback: function ($$v) {
                  _vm.val = $$v
                },
                expression: "val",
              },
            },
            [
              _c(
                "template",
                { slot: "addonBefore" },
                [_vm._t("addonBefore")],
                2
              ),
              _c("template", { slot: "addonAfter" }, [_vm._t("addonAfter")], 2),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }